body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

.app {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #1a1a1a;
  color: white;
}

.toggle-switch-container {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  border-radius: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  font-size: 16px;
  color: white;
  transition: .4s;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  z-index: 10;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.sidebar.visible {
  transform: translateX(0);
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.sidebar a:hover {
  color: #2196F3;
}

.sidebar hr {
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin: 20px 0;
}

.page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: margin-left 0.3s ease;
}

.sidebar.visible + .page-container {
  margin-left: 200px;
}

.page-content {
  max-width: 800px;
  padding: 20px;
  margin-left: 300px;
  height: calc(100vh - 40px); /* Account for padding */
  overflow-y: auto;
  position: relative;
}


.bubble {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  word-wrap: break-word;
  transition: opacity 0.5s ease, transform 0.3s ease;
  opacity: 0;
  transform: scale(0);
}

.bubble.visible {
  opacity: 1;
  transform: scale(1);
}

.bubble:hover {
  transform: scale(1.1);
}

@media (max-width: 600px) {
  .bubble {
    width: 80px;
    height: 80px;
    font-size: 12px;
  }
  
  .sidebar {
    width: 100%;
  }
  
  .sidebar.visible + .page-container {
    margin-left: 0;
  }
}